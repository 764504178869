$black_1: #15222f;
$black_2: #535961;
$black: #2e3452;
$black_ew: #4e515b;
$pure_black: #000000;

$grey_background: #e5e5e5;
$grey_1: #82878d;
$grey_2: #d1d1d1;
$grey_3: rgba(46, 52, 82, 0.13);

$mst_blue: #7b7bf5;
$blue_1: #5d5cf5;

$dark_background_1: #212121;
$dark_background_2: #2e2e2e;
$dark_background_3: #292929;

$white_1: #fafafa;
$white_2: #f1f3f6;
$white_3: #e5e7ea;

$warn_red: #ff5353;
