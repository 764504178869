@mixin flex($direction: row, $justify: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}
@mixin font-details(
    $font-family: Open Sans,
    $font-size: 1rem,
    $font-weight: 400,
    $line-height: null,
    $color: null,
    $text-align: null
) {
    font-family: $font-family, sans-serif !important;
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    @if ($line-height) {
        line-height: $line-height !important;
    }
    @if ($text-align) {
        text-align: $text-align !important;
    }
    @if ($color) {
        color: $color !important;
    }
}
