@import 'utils/stylesheets/colors.scss';

.inactive-main-container {
    padding: 16px;
    text-align: center;
    .headline-3 {
        margin-bottom: 8px;
    }
    .body-3 {
        margin-bottom: 28px;
    }
    .headline-1 {
        color: #2E3452;
        margin-top: 24px;
    }
}

.dark-App {
    .inactive-main-container .headline-1 {
        color: $grey_background;
    }
}