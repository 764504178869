@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.edit-suggestion-container {
    padding: 0 32px;

    textarea {
        height: 100px;
    }

    .header-text {
        margin-bottom: 10px;
    }

    .text-area-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: flex-end;
    }

    .footer-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;

        .close-button-container {
            text-align: right;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}
