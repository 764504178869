@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.reply-anonymous-container {
    padding: 0 32px;

    textarea {
        height: 100px;
    }

    .footer-text {
        margin-top: 10px;
    }

    .close-button-container {
        text-align: right;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .reply-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
    }
}
