@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.pulse-modal {
    padding: 0 32px;
    height: 600px;

    .separator-line {
        border: 0;
        height: 1px;
        background-color: $grey_1;
        margin: 16px 0;
        opacity: 0.5;
    }
        .questions-wrapper {
            height: 380px;
            padding-right: 25px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: $grey_1;
            padding-bottom: 80px;

            .question-container {
                margin-bottom: 10px;
                width: 100%;
            
                h4 {
                    margin-right: 10px;
                    white-space: normal;
                    word-wrap: break-word;
            
                    span {
                        color: $grey_1;
                    }
                }
            }
        }

    .sticky-questions-footer {
        position: fixed;
        bottom: 10px;
        left: 0;
        width: 100%;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        .footer-questions-content {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;
        }
    }
}
