@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.recognize-modal {
    padding: 0 32px 32px;
    &-balance {
        margin-top: 14px;
        .subtitle-2 {
            text-transform: uppercase;
        }
        .subtitle-4 {
            color: $blue_1;
        }
    }
    .optional-label {
        opacity: 0.5; /* Adjust opacity as needed */
    }
    .submit_btn {
        text-align: right;
        margin-top: 20px;
    }
    .enhance_ai_btn {
        width: 100%;
        @include flex(row, space-between, flex-end);
    }
}

//desktop styles
@media (min-width: 560px) {
    .recognize-modal {
        &-heading {
            @include flex(row, space-between, center);
        }
        &-balance {
            margin: 0;
        }
    }
}
