@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.responses-modal {
    padding: 0 32px;
    .separator-line {
        border: 0;
        height: 1px;
        background-color: $grey_1;
        margin: 16px 0;
        opacity: 0.5;
    }
    .responses-container {
        height: 450px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $grey_1;
    }
    .sticky-responses-footer {
        position: fixed;
        bottom: 30px;
        left: 0;
        width: 100%;
        padding-top: 20px;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        .footer-responses-content {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;
        }
    }
}