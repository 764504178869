@import 'utils/stylesheets/mixins.scss';
@import 'utils/stylesheets/colors.scss';

.feedback-container {
    padding: 0 32px;

    .headline-2 {
        padding: 10px 0 0 0;
    }

    textarea: {
        height: 80px;
    }

    .feedback-title {
        margin-bottom: 20px;
    }

    .feedback-config {
        margin-top: 10px;
        @include flex(column, flex-start, flex-start);
    }

    .text-area-label {
        width: 100%;
        @include flex(row, space-between, flex-end);
    }

    .tab-buttons {
        @include flex(row, center);
        margin-bottom: 10px;
        border-bottom: 1px solid $grey_3;

        .tab-label[aria-selected='true'] {
            span {
                color: $blue_1;
            }
        }

        .tab-label {
            span {
                font-weight: 600;
                color: $pure_black;
                padding: 0 24px;
            }

            &::after {
                height: 1px !important;
                bottom: -1px;
            }

            &:hover::before {
                height: 1px !important;
                bottom: -1px;
            }
        }
    }
}
