@import 'utils/stylesheets/colors.scss';

.custom-image-select {
    position: relative;

    .image-select-button {
        text-align: left;
        justify-content: flex-start;
        width: 100%;
        font-weight: 400;
        color: $grey_1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dropdown-arrow {
            margin-left: 8px;
            transition: transform 0.3s ease;
        }

        &.open .dropdown-arrow {
            transform: rotate(180deg);
        }

        &:hover {
            color: $grey_1;
        }
    }
}

.image-dropdown {
    position: absolute;
    bottom: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    z-index: 1000;
    padding: 10px 10px 0 10px;
    max-height: 275px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.image-item {
    width: 104px;
    height: 104px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.image-item.selected {
    transform: scale(1.05);
}

.image-element {
    object-fit: cover;
    border: none;
}

.image-item.selected .image-element {
    border: 3px solid $blue_1;
}
