@import 'utils/stylesheets/colors.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: $mst_blue;
    text-decoration: underline;
    cursor: pointer;
}

button {
    border: 0;
}

.hidden {
    display: none;
}

.text-center {
    text-align: center;
}

.margin-top-16 {
    margin-top: 16px;
}
.margin-bottom-8 {
    margin-bottom: 8px;
}
.margin-bottom-16 {
    margin-bottom: 16px;
}

.font-size-12 {
    font-size: 0.75rem;
}
.font-size-16 {
    font-size: 1rem;
}

.headline-3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $black_1;
    font-weight: 600;
}
.headline-2 {
    @extend .headline-3;
    font-size: 1rem;
    padding: 1rem 0;
}
.headline-1 {
    @extend .headline-3;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.body-2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5em;
    color: $grey_1;
}
.body-3 {
    @extend .body-2;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: $black_2;
}

.subtitle-2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $black_ew;
}
.subtitle-4 {
    @extend .subtitle-2;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $black_1;
}
.warning-message {
    font-size: 0.875rem;
    line-height: 1.5em;
    color: $warn_red;
    margin: 0;
}
.back-navigation {
    color: $blue_1;
    top: 0;
    padding-top: 16px;
    z-index: 1;
    span {
        cursor: pointer;
    }
}

.close-icon {
    background: $white_1;
}

.modal-button-container {
    text-align: right;
    button {
        width: 96px;
        height: 32px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        &:first-child {
            color: white;
            background-color: $blue_1;
        }
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
/* width */
::-webkit-scrollbar {
    width: 4px;
}

//dark theme global classes

.dark-App {
    .headline-1,
    .headline-2,
    .headline-3,
    .body-3,
    .subtitle-4 {
        color: $white_1;
    }
    .body-2 {
        color: $white_3;
    }
    .subtitle-2 {
        color: $white_2;
    }
    .image-select-button {
        color: $white_3;

        &:hover {
            color: $white_1;
        }
    }

    .image-dropdown {
        background-color: $dark_background_1;
        border: 1px solid $black_2;
    }

    .close-icon {
        background: $black_2;
    }
}
