@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';
.celebration-modal {
    @include flex(column, null, null);
    padding: 0 32px;
}
.subheader-wrapper {
    padding: 5px 0 1rem 0;
}
.subheader {
    opacity: 0.5;
    margin-bottom: 1rem;
}
