@import 'utils/stylesheets/mixins.scss';
.disabled-option {
    @include flex(row, null, center);
    width: 100%;
    opacity: 0.5;
    cursor: not-allowed;
    span {
        font-style: italic;
    }
}
