@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.suggestion-box-container {
    padding: 0 32px;
    textarea: {
        height: 80px;
    }
    .suggestion-box-title {
        margin-bottom: 20px;
    }

    .suggestion-box-config {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }

    .text-area-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: flex-end;
    }
}
