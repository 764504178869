@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.option-container {
    border: 1px solid $white_3;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 42px 28px 16px;
    margin-top: 16px;
    cursor: pointer;
    @include flex(row-reverse, space-between, center);
    &:hover, &:focus {
        border: 1px solid $mst_blue;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    > div {
        margin-left: 8px;
        flex: 1;
    }
    .headline-1 span {
        font-weight: normal;
    }

    .external-link {
        display: flex;
        > span {
            margin: 2px 0 0 4px;
        }
    }

    .illustration-icons{
        width: 60px;
        height: 43px;
        margin-left: 15px;
        flex-shrink: 0;
    }
    .body-3 {
        @include font-details('Montserrat', 0.75rem, null, 1.25rem);
        margin-top: 4px;
        width: 192px;
    }
}
