@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.tutorial-main-container {
    padding: 14px 16px 24px;
    background-color: $grey_background;
    .tutorial-heading-container {
        padding: 0px 22px 24px;
    }
    .tutorial-body-container {
        .tutorial-image-desktop {
            display: none;
        }
        .tutorial-body {
            margin-bottom: 16px;
        }
        .tutorial-accordian {
            background-color: white;
            border-radius: 8px;
            padding: 16px;
            cursor: pointer;
            @include flex(row, space-between, center);
        }
        .body-3 {
            color: $black_1;
        }
        .active-accordian {
            background-color: $mst_blue;
        }
        .active-accordian.body-3 {
            color: white;
            font-weight: 600;
        }
        .tutorial-image {
            width: 100%;
            border-radius: 12px;
            margin-top: 16px;
        }
    }
    .tutorial-footer-container {
        background-color: white;
        border-radius: 8px;
        text-align: center;
        padding: 22px 0 38px;
        .headline-3 {
            color: #2E3452;
            margin-top: 24px;
        }
        .book-a-demo-btn {
            background-color: $mst_blue;
            padding: 8px 72px;
            font-weight: 600;
            color: white;
            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            border-radius: 4px;
            margin-top: 16px;
            cursor: pointer;
        }
        .or {
            font-family: 'Poppins', sans-serif;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #A0AEC0;
            margin: 12px 0 8px;
        }
        .contact-us {
            font-family: 'Poppins', sans-serif;
            color: #2E3452;
            a {
                color: $mst_blue;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.dark-tutorial-main-container {
    background-color: $dark_background_1;
    .tutorial-body-container {
        .tutorial-accordian {
            background-color: $dark_background_3;
        }
        .body-3 {
            color: $white_1;
        }
    }
    .tutorial-footer-container {
        background-color: $dark_background_3;
        .headline-3 {
            color: $grey_background;
        }
        .contact-us {
            color: $white_2;
        }
    }
}


//desktop styles
@media (min-width: 768px) {
    .tutorial-main-container {
        .tutorial-image {
            display: none;
        }
        .tutorial-body-container {
            @include flex(row, space-between, center);
            background-color: white;
            padding: 23px 20px;
            border-radius: 8px;
            margin-bottom: 14px;
            .tutorial-image-desktop {
                display: block;
                width: 50%;
            }
            > div {
                width: 50%;
                padding-right: 30px;
                min-height: 312px;
            }
            .tutorial-accordian {
                box-shadow: 0px 4px 40px rgba(178, 158, 255, 0.1);
            }
        }
        .tutorial-footer-container {
            @include flex(row, center, center);
            > div {
                margin-left: 92px;
            }
        }
    }
    .dark-tutorial-main-container {
        .tutorial-body-container {
            background-color: $dark_background_2;
        }
    }
}