.select-gif-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 15px 0;
    justify-content: space-between;
    height: 100px;

    .select-gif-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 67%;

        .gif-label-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }
    }

    .selected-image-container {
        position: relative;
        display: inline-block;

        .image-select {
            object-fit: cover;
        }
    }

    .close-icon {
        position: absolute;
        top: -8px;
        right: -8px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        font-size: 14px;
    }
}
