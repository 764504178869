@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.confirm-container {
    padding: 24px;
    margin-top: 40px;
    border: 1px solid $white_3;
    box-sizing: border-box;
    border-radius: 8px;
}
