@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.feedback-container {
    padding: 0 32px;

    .send-feedback-form {
        padding-bottom: 20px;
    }

    textarea: {
        height: 80px;
    }
    .feedback-title {
        margin-bottom: 20px;
    }

    .feedback-config {
        margin-top: 10px;
        @include flex(column, flex-start, flex-start);
    }

    .text-area-label {
        width: 100%;
        @include flex(row, space-between, flex-end);
    }
}
