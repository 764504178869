@import 'utils/stylesheets/colors.scss';
@import 'utils/stylesheets/mixins.scss';

.choose-action {
    padding: 0 14px 40px;
    .whats-on-mind {
        .headline-2 {
            padding-bottom: 0;
        }
    }
    .setup-reward-container {
        border: none;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 24px 24px 54px 28px;
        border-radius: 8px;
        .subtitle-4 {
            margin-top: 18px;
        }
        .body-3 {
            @include font-details('Montserrat', 0.75rem, null, 1.25rem);
            margin-top: 4px;
            width: 192px;
        }
        button {
            padding: 5.5px 32.5px;
            background: $blue_1;
            border-radius: 8px;
            @include font-details('Open Sans', 0.875rem, null, null, white);
            margin-top: 12px;
            cursor: pointer;
        }
    }
    .option-container {
        border: none;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        &:hover {
            border: 1px solid $blue_1;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        }
        .body-3 {
            @include font-details('Montserrat', 0.75rem, null, 1.25rem);
            margin-top: 4px;
            width: 192px;
        }
    }
}

//dark theme styles
.dark-choose-action {
    .setup-reward-container {
        border: 0;
        box-shadow: none;
        background-color: $dark_background_2;
    }
    .option-container {
        border-color: transparent;
        background-color: $dark_background_2;
    }
    .whats-on-mind {
        .headline-3 {
            color: $white_1;
        }
    }
}

//desktop styles
@media (min-width: 560px) {
    .choose-action {
        padding: 0px 16px 40px 25px;
        .whats-on-mind {
            margin-top: 8px;
            .headline-2 {
                padding-bottom: 0;
            }
        }
        .option-card-container {
            @include flex(row, center, center);
            flex-wrap: wrap;
            padding-left: 5px;
            .option-container {
                @include flex(row, center, center);
                max-width: 285px;
                height: 93px;
                padding: 28px 28px 28px 15px;
                &:nth-child(odd) {
                    margin-right: 16px;
                }
            }
        }
        .setup-reward-container {
            @include flex(row-reverse, space-between, center);
            width: 464px;
            margin: 16px auto 0;
            svg {
                width: 100%;
            }
            .subtitle-4 {
                margin-top: 0;
            }
        }
        > div:last-child {
            margin-top: 28px;
        }
    }
}

// Mobile styles
@media (max-width: 559px) {
    .option-container {
        padding: 24px 15px !important;
        .body-3 {
            margin-top: 2px;
            width: 100%;
            font-size: 0.5rem;
        }

        .headline-1 {
            font-size: 0.75rem;
        }
        .illustration-icons {
            margin-left: 0px !important;
        }
    }
    .whats-on-mind {
        .headline-2 {
            font-size: 0.875rem;
        }
    }
    .setup-reward-container {
        margin-top: 16px;
    }
}
